import {Link, useLocation, useNavigate} from 'react-router-dom';
import ButtonIcon from 'components/ButtonIcon';
import {useForm} from 'react-hook-form';
import {requestBackendLogin} from 'util/requests';
import {useContext, useState} from 'react';
import {AuthContext} from 'AuthContext';
import {saveAuthData} from 'util/storage';
import {getTokenData} from 'util/auth';
import backgroundImage from 'assets/images/background.jpg';
import logo from 'assets/images/v4techlogo.png';

import './styles.css';

type CredentialsDTO = {
    username: string;
    password: string;
};

type LocationState = {
    from: string;
}

const Login = () => {
    const locationEnt = useLocation();
    const location = locationEnt.state as { from?: { pathname: string } };
    ;
    const navigate = useNavigate();

    const {from} = locationEnt.state || {from: {pathname: '/dashboards'}};

    const {setAuthContextData} = useContext(AuthContext);

    const [hasError, setHasError] = useState(false);

    const {register, handleSubmit, formState: {errors}} = useForm<CredentialsDTO>();

    const sectionStyle = {
        width: '100vw',  // 100% da viewport width
        height: '100vh', // 100% da viewport height
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',    // Cobrir toda a área disponível
        backgroundPosition: 'center' // Centralizar a imagem de fundo
    };

    const onSubmit = (formData: CredentialsDTO) => {
        requestBackendLogin(formData)
            .then((response) => {
                saveAuthData(response.data);
                setHasError(false);
                setAuthContextData({
                    authenticated: true,
                    tokenData: getTokenData(),
                })
                navigate(from, {replace: true});
            })
            .catch((error) => {
                setHasError(true);
                console.log('ERRO', error);
            });
    };

    return (
        <>
            <div className="base-card-login login-card">
                <h1>M4T3M4RK3T1NG</h1>
                {hasError && (
                    <div className="alert alert-danger">Erro ao tentar efetuar o login</div>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-2">
                        <input
                            {...register('username', {
                                required: 'Campo obrigatório',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Email inválido'
                                }
                            })}
                            type="text"
                            className={`form-control base-input ${errors.username ? 'is-invalid' : ''}`}
                            placeholder="Login"
                            name="username"
                        />
                        <div className="invalid-feedback d-block">{errors.username?.message}</div>
                    </div>
                    <div className="mb-3">
                        <input
                            {...register('password', {
                                required: 'Campo obrigatório'
                            })}
                            type="password"
                            className={`form-control base-input ${errors.password ? 'is-invalid' : ''}`}
                            placeholder="Senha"
                            name="password"
                        />
                        <div className="invalid-feedback d-block">{errors.password?.message}</div>
                    </div>
                    <div className="login-submit">
                        <ButtonIcon text="Entrar"/>
                    </div>
                </form>

            </div>
            <div className="container logo-image">
                <img src={logo} alt="Logo" className="img-fluid w-50 mt-3"/>
            </div>
        </>
    )
        ;
};

export default Login;
