import './styles.css';
import 'bootstrap/js/src/collapse.js';
import logo from 'assets/images/v4techlogo.png';
import { TbAntennaBars5 } from "react-icons/tb";
import { LuLogOut } from "react-icons/lu";


import {Link, NavLink, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import { useContext } from 'react';
import { AuthContext } from 'AuthContext';
import {getTokenData, hasAnyRoles, isAuthenticated} from 'util/auth';
import { removeAuthData } from 'util/storage';

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);

  const { authContextData, setAuthContextData } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      setAuthContextData({
        authenticated: true,
        tokenData: getTokenData(),
      });
    } else {
      setAuthContextData({
        authenticated: false,
      });
    }
  }, [setAuthContextData]);

  const handleLogoutClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    removeAuthData();
    setAuthContextData({
      authenticated: false,
    });

    navigate('/', { replace: true });
  };


  return (
    <nav className="navbar navbar-dark bg-primary main-nav p-2">
      <div className="container-fluid">
        <Link to="/dashboards" className="nav-logo-text">
          <img src={logo} alt="Logo" style={{ minWidth: '100px', width: '150px' }}/>
        </Link>
      </div>
        <div className="container-fluid main-menu">
          <ul className="navbar-nav main-menu ">

            {hasAnyRoles(['ROLE_ADMIN']) && (
                <li>
                  <NavLink to="/dashboards" className={({isActive}) => isActive ? 'active' : undefined}>
                    <TbAntennaBars5 size={30}/>
                  </NavLink>
                </li>
            )}
            {authContextData.authenticated ? (
                <>
                  <span className="nav-username">{authContextData.tokenData?.user_name}</span>
                  <a href="#logout" onClick={handleLogoutClick}>
                    <LuLogOut size={30}/>
                  </a>
                </>
            ) : (
                <Link to="/admin/auth/login">LOGIN</Link>
            )}
          </ul>
        </div>
</nav>
)
  ;
};

export default Navbar;
